import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/hero'

export default ({ data, location }) => {
  const heroImage = data.heroImage
  return (
    <>
      <Layout>
        <div id="sub-page">
        <SEO
            title="Error 404"
            description="Error 404 - Something must have gone wrong for you to get here!  Please head back to the UNICOM Engineering home page, use the search page, or try the sitemap."
            url={data.site.siteMetadata.siteUrl + location.pathname} 
            image={data.site.siteMetadata.siteUrl + heroImage.childImageSharp.fluid.src}
            />
          <Hero
            class="intro-100"
            image={heroImage.childImageSharp.fluid}
            title="Error 404"
            subtitle="Oops, we couldn't find the page you're looking for, please go to our home page by clicking the button below."
            ctaslug="/"
            ctatext="Take me there"
            type="404"
          />
        </div>

        <main></main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }      
    heroImage: file(name: { eq: "404-not-found" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`